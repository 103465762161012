<template>
  <c-step
    stepperGrpCd="EDU_STEP_CD"
    :currentStepCd="popupParam.stepCd"
    :param="popupParam"
    v-model="popupParam.stepCd"
    @currentStep="currentStep"
    >
  </c-step>
</template>

<script>
export default {
  name: 'education-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    currentStep(step) {
      this.popupParam.stepCd = step;
    }
  }
};
</script>
